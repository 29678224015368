/* 
  Syntax Highlighting for Code Snippets

  https://www.bridgetownrb.com/docs/liquid/tags#stylesheets-for-syntax-highlighting

  Other styles available eg. https://github.com/jwarby/jekyll-pygments-themes
  To use another style, delete all styles in this file and replace them with
  the new styles. Or create your own!

*/

code {
    background-color: #11111b;
    color: #74c7ec;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: "JetBrains Mono", monospace;
}

pre.highlight {
  padding: 16px;
  background-color: #11111b;
}

.highlight .hll { background-color: #11111b } /* Mocha background */
.highlight .c { color: #f5e0dc; font-style: italic } /* Comment */
.highlight .err { color: #f38ba8; background-color: #f2d4d5 } /* Error */
.highlight .k { color: #c9cbff; font-weight: bold } /* Keyword */
.highlight .o { color: #c9cbff; font-weight: bold } /* Operator */
.highlight .cm { color: #f5e0dc; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: #f5e0dc; font-weight: bold; font-style: italic } /* Comment.Preproc */
.highlight .c1 { color: #f5e0dc; font-style: italic } /* Comment.Single */
.highlight .cs { color: #f5e0dc; font-weight: bold; font-style: italic } /* Comment.Special */
.highlight .gd { color: #c9cbff; background-color: #f5e0dc } /* Generic.Deleted */
.highlight .ge { color: #c9cbff; font-style: italic } /* Generic.Emph */
.highlight .gr { color: #f38ba8 } /* Generic.Error */
.highlight .gh { color: #f5e0dc } /* Generic.Heading */
.highlight .gi { color: #c9cbff; background-color: #a6e3a1 } /* Generic.Inserted */
.highlight .go { color: #d9d9d9 } /* Generic.Output */
.highlight .gp { color: #555555 } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #f5e0dc } /* Generic.Subheading */
.highlight .gt { color: #f38ba8 } /* Generic.Traceback */
.highlight .kc { color: #c9cbff; font-weight: bold } /* Keyword.Constant */
.highlight .kd { color: #c9cbff; font-weight: bold } /* Keyword.Declaration */
.highlight .kn { color: #c9cbff; font-weight: bold } /* Keyword.Namespace */
.highlight .kp { color: #c9cbff; font-weight: bold } /* Keyword.Pseudo */
.highlight .kr { color: #c9cbff; font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: #89dceb; font-weight: bold } /* Keyword.Type */
.highlight .m { color: #f9e2af } /* Literal.Number */
.highlight .s { color: #f5c2e7 } /* Literal.String */
.highlight .na { color: #c9cbff } /* Name.Attribute */
.highlight .nb { color: #f9e2af } /* Name.Builtin */
.highlight .nc { color: #89dceb; font-weight: bold } /* Name.Class */
.highlight .no { color: #c9cbff } /* Name.Constant */
.highlight .nd { color: #89dceb; font-weight: bold } /* Name.Decorator */
.highlight .ni { color: #f38ba8 } /* Name.Entity */
.highlight .ne { color: #f38ba8; font-weight: bold } /* Name.Exception */
.highlight .nf { color: #f38ba8; font-weight: bold } /* Name.Function */
.highlight .nl { color: #f38ba8; font-weight: bold } /* Name.Label */
.highlight .nn { color: #555555 } /* Name.Namespace */
.highlight .nt { color: #89dceb } /* Name.Tag */
.highlight .nv { color: #c9cbff } /* Name.Variable */
.highlight .ow { color: #c9cbff; font-weight: bold } /* Operator.Word */
.highlight .w { color: #bbbbbb } /* Text.Whitespace */
.highlight .mf { color: #f9e2af } /* Literal.Number.Float */
.highlight .mh { color: #f9e2af } /* Literal.Number.Hex */
.highlight .mi { color: #f9e2af } /* Literal.Number.Integer */
.highlight .mo { color: #f9e2af } /* Literal.Number.Oct */
.highlight .sb { color: #f5c2e7 } /* Literal.String.Backtick */
.highlight .sc { color: #f5c2e7 } /* Literal.String.Char */
.highlight .sd { color: #f5c2e7 } /* Literal.String.Doc */
.highlight .s2 { color: #f5c2e7 } /* Literal.String.Double */
.highlight .se { color: #f5c2e7 } /* Literal.String.Escape */
.highlight .sh { color: #f5c2e7 } /* Literal.String.Heredoc */
.highlight .si { color: #f5c2e7 } /* Literal.String.Interpol */
.highlight .sx { color: #f5c2e7 } /* Literal.String.Other */
.highlight .sr { color: #89dceb } /* Literal.String.Regex */
.highlight .s1 { color: #f5c2e7 } /* Literal.String.Single */
.highlight .ss { color: #f38ba8 } /* Literal.String.Symbol */
.highlight .bp { color: #f9e2af } /* Name.Builtin.Pseudo */
.highlight .vc { color: #c9cbff } /* Name.Variable.Class */
.highlight .vg { color: #c9cbff } /* Name.Variable.Global */
.highlight .vi { color: #c9cbff } /* Name.Variable.Instance */
.highlight .il { color: #f9e2af } /* Literal.Number.Integer.Long */

